import React from 'react';
import { rateStrengthValues } from '../../services/rate-quality'

const StackedIcon = ({ strength = 'BETTER' }) => {
  const level = rateStrengthValues[strength]

  return (
    <svg width="56px" height="60px" viewBox="0 0 56 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>8FD37AE5-7330-4003-9CD4-7BE34D28BFE3</title>
      <g id="Coverage-Details" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Coverage_Info" transform="translate(-437.000000, -423.000000)" fillRule="nonzero">
          <g id="iconfinder_layers_stack_layer_5507109" transform="translate(437.000000, 423.000000)">
            <path d="M55.2727273,15.7272727 C55.2710317,16.6127846 54.7395097,17.4288271 53.8818182,17.8627273 L29.3363636,30.1354545 C28.4925425,30.5739169 27.452912,30.5739169 26.6090909,30.1354545 L2.06363636,17.8627273 C1.20915331,17.4268952 0.681050149,16.6112207 0.681050149,15.7272727 C0.681050149,14.8433247 1.20915331,14.0276503 2.06363636,13.5918182 L26.6090909,1.31909091 C27.452912,0.880628594 28.4925425,0.880628594 29.3363636,1.31909091 L53.8818182,13.5918182 C54.7395097,14.0257183 55.2710317,14.8417609 55.2727273,15.7272727 Z"  id="Path"
              fill={level > 2 ? "#F16322" : "#E9E9E9"}></path>
            <path d="M53.8818182,28.3190909 L49.0545455,25.8645455 L31.9818182,34.4063636 C30.7670507,35.0260204 29.3962888,35.3555666 28,35.3636364 C26.5934772,35.366537 25.2108519,35.0363999 23.9909091,34.4063636 L6.94545455,25.8890909 L2.11818182,28.3436364 C1.26369877,28.7794684 0.735595604,29.5951429 0.735595604,30.4790909 C0.735595604,31.3630389 1.26369877,32.1787134 2.11818182,32.6145455 L26.6636364,44.8872727 C27.5074575,45.325735 28.547088,45.325735 29.3909091,44.8872727 L53.9363636,32.6145455 C54.7908467,32.1787134 55.3189499,31.3630389 55.3189499,30.4790909 C55.3189499,29.5951429 54.7908467,28.7794684 53.9363636,28.3436364 L53.8818182,28.3190909 Z" id="Path"
              fill={level > 1 ? "#F16322" : "#E9E9E9"}></path>
            <path d="M53.8818182,43.0463636 L49.0545455,40.5918182 L31.9818182,49.1336364 C30.7670507,49.7532932 29.3962888,50.0828394 28,50.0909091 C26.5934772,50.0938097 25.2108519,49.7636727 23.9909091,49.1336364 L6.94545455,40.6163636 L2.11818182,43.0709091 C1.26369877,43.5067412 0.735595604,44.3224157 0.735595604,45.2063636 C0.735595604,46.0903116 1.26369877,46.9059861 2.11818182,47.3418182 L26.6636364,59.6145455 C27.5074575,60.0530078 28.547088,60.0530078 29.3909091,59.6145455 L53.9363636,47.3418182 C54.7908467,46.9059861 55.3189499,46.0903116 55.3189499,45.2063636 C55.3189499,44.3224157 54.7908467,43.5067412 53.9363636,43.0709091 L53.8818182,43.0463636 Z" id="Path"
              fill={level > 0 ? "#F16322" : "#E9E9E9"}></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StackedIcon
