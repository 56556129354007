export const GETTING_QUOTE   = 'GETTING_QUOTE';
export const RECEIVING_QUOTE = 'RECEIVING_QUOTE';
export const CREATING_QUOTE  = 'CREATING_QUOTE';
export const CREATED_QUOTE   = 'CREATED_QUOTE';
export const UPDATING_QUOTE  = 'UPDATING_QUOTE';
export const UPDATED_QUOTE   = 'UPDATED_QUOTE';
export const SEARCHED_ZIP_CODE     = 'SEARCHED_ZIP_CODE';
export const SEARCHING_ZIP_CODE    = 'SEARCHING_ZIP_CODE';
export const RESET_ADDRESS_OPTIONS = 'RESET_ADDRESS_OPTIONS';
export const USERNAME_QUOTE        =   'USERNAME_QUOTE';
export const PHONE_QUOTE      = 'PHONE_QUOTE';
export const EMAILING_QUOTE   = 'EMAILING_QUOTE';
export const EMAILED_QUOTE    = 'EMAILED_QUOTE';
export const PURCHASING_QUOTE = 'PURCHASING_QUOTE';
export const PURCHASED_QUOTE  = 'PURCHASED_QUOTE';
export const BINDING_QUOTE = 'BINDING_QUOTE';
export const FINISH_BINDING_QUOTE = 'FINISH_BINDING_QUOTE';
export const SET_QUICK_QUOTE_INITIAL_LOAD_STATUS = 'SET_QUICK_QUOTE_INITIAL_LOAD_STATUS'; 