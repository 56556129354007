export const PRIMARY   = "primary";
export const SECONDARY = "secondary";
export const ACCENT    = "accent";
export const WARNING   = "warning";
export const DANGER    = "danger";
export const SUCCESS   = "success";
export const INFO      = "info";
export const DARK      = "dark";
export const LIGHT     = "light";

